<template>
  <div class="website-container">
    <div class="content-container">
      <div class="form-item">
        <div class="item-title required">链接地址</div>
        <van-field
          v-model="webpageData.web_address"
          placeholder="请输入链接地址"
          class="item-input"
        />
      </div>
      <div class="form-item textarea-box">
        <div class="item-title required">标题</div>
        <van-field
          v-model="webpageData.web_title"
          placeholder="请输入网页标题"
          class="item-input"
          maxlength="16"
          show-word-limit
        />
      </div>
      <div class="form-item">
        <div class="item-title">描述</div>
        <van-field
          v-model="webpageData.web_desc"
          placeholder="请输入网页描述"
          class="item-input"
        />
      </div>
      <div class="add_pic">
        <div class="item-title">封面</div>
        <div class="right">
          <div class="top" v-if="!previewUrl">
            <img src="@/assets/images/add_pic.png" alt="" @click="choosePic" />
          </div>
          <div class="preview-img" v-else>
            <img
              :src="previewUrl ? previewUrl : '@/assets/images/add_pic.png'"
              alt=""
            />
            <img
              class="img-close"
              @click="delFun"
              src="@/assets/images/img_close.png"
            />
          </div>
          <div class="bottom">
            建议图片尺寸160*160像素，JPG、PNG格式，图片小于2M
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button fixed">
      <button class="cancel-btn" @click="cancel">取消</button>
      <button
        class="confirm-btn"
        :disabled="confirmDisabled"
        @click="confirm"
        :class="{ 'confirm-btn__disabled': confirmDisabled }"
      >
        确认
      </button>
    </div>
    <input
      type="file"
      ref="upload"
      class="input-file-btn"
      @change="fileChange"
    />
  </div>
</template>
<script>
import uploadImgMix from "@/globalWidget/mixin/uploadImgMix.vue";
export default {
  name: "addWebsite",
  components: {},
  mixins: [uploadImgMix],
  data() {
    return {
      webpageData: {
        web_address: "",
        web_title: "",
        web_desc: "",
        previewUrl: "",
      },
    };
  },
  computed: {
    confirmDisabled() {
      return !(this.webpageData.web_address && this.webpageData.web_title);
    },
  },
  mounted() {
    this.addClass();
  },
  methods: {
    // 点击选择图片
    choosePic() {
      this.$refs.upload.click();
    },
    // 点击取消按钮
    cancel() {
      this.$router.go(-1);
    },
    // 点击确定按钮
    confirm() {
      if (!this.confirmDisabled) {
        this.webpageData.previewUrl = this.previewUrl;
        this.$store.commit("setWebpageData", this.webpageData);
        console.log(this.$store);
        this.$router.go(-1);
      }
    },
    // 修改vant组件改样式的问题
    addClass() {
      // let ele1 = document.getElementsByClassName("van-field__word-limit");
      // ele1[0].style.cssText = "position:absolute;top: 0;right: 0;";
      let ele2 = document.getElementsByClassName("van-field__label");
      ele2.forEach((element) => {
        element.style.cssText = "margin-right: 0rem";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.website-container {
  background-color: #f5f6f9;
  padding: 0.32rem;
  .content-container {
    padding: 0 0.32rem;
    background-color: #fff;
    border-radius: 0.16rem;
    .add_pic {
      display: flex;
      padding: 0.32rem 0;
      .right {
        img {
          width: 1.44rem;
          height: 1.44rem;
        }
        .preview-img {
          position: relative;
          img {
            width: 1.44rem;
            height: 1.44rem;
          }
          .img-close {
            position: absolute;
            width: 0.32rem;
            height: 0.32rem;
            top: -0.16rem;
            left: 1.28rem;
          }
        }
        .bottom {
          width: 4.52rem;
          padding-top: 0.16rem;
          color: #666;
          font-size: 0.2rem;
          // font-size: 0.4rem;
          // transform:scale(0.5);
        }
      }
    }
  }
  .form-item {
    display: flex;
    height: 0.96rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .item-title {
    font-size: 0.28rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.4rem;
    flex-shrink: 0;
    width: 2rem;
  }
  .item-input {
    width: 4.58rem;
  }
  .required::after {
    content: "*";
    font-size: 0.28rem;
    font-weight: 400;
    color: #ff4747;
    line-height: 0.4rem;
    margin-left: 0.08rem;
  }
  .textarea-box {
    height: auto;
    align-items: flex-start;
    padding: 0.28rem 0;
  }
  .footer-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1.12rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.16rem 0rem rgba(0, 0, 0, 0.08);
    button {
      border: none;
      outline: none;
      width: 3.28rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      font-weight: 500;
      line-height: 0.48rem;
    }
    .cancel-btn {
      border: 1px solid #cccccc;
      background-color: #ffffff;
      color: #333333;
    }
    .confirm-btn {
      background: #105cfb;
      color: #ffffff;
    }
    .confirm-btn__disabled {
      background: #f5f5f5;
      color: #b2b2b2;
    }
  }
  .input-file-btn {
    display: none;
  }
}
</style>
<style lang="scss">
.form-item {
  .van-cell {
    padding: 0;
  }
}
</style>
