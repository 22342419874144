<script>
import { uploadImg } from "../utils/upload.js";
export default {
  name: "customerContact",
  components: {},
  data() {
    return {
      previewUrl: "",
      file_name: "",
      file_size: "",
      file_type: "",
      fileSize: 2,
      extension: ["jpg", "png", "jpeg", "gif", "JPG", "PNG", "JPEG", "GIF"],
      loading: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    fileChange() {
      let file = this.$refs.upload.files[0],
        extension = file.name.substring(file.name.lastIndexOf(".") + 1),
        size = file.size / 1024 / 1024;
      this.file_name = file.name;
      this.file_size = file.size;
      this.file_type = extension;
      if (!this.extension.includes(extension)) {
        this.$toast("请上传正确的图片格式");
        return;
      }
      if (this.fileSize < size) {
        this.$toast(`文件大小必须小于${this.fileSize}M`);
        return;
      }
      this.uploadFun(file);
      this.preview(file);
    },
    uploadFun(file) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.loading = true;
      let cookie = {};
      document.cookie.split("; ").forEach((e) => {
        cookie[e.split("=")[0]] = e.split("=")[1];
      });
      let that = this;
      uploadImg(
        (result) => {
          this.$toast.clear();
          this.loading = false;
          if (result.code === 0) {
            this.previewUrl = result.data.access_url;
          } else {
            this.$toast("上传文件错误");
            this.previewUrl = "";
          }
        },
        (result) => {
          console.log(result);
          that.$toast("上传文件错误");
        },
        (progress) => {
          console.log(progress);
        },
        file,
        cookie.with_app_id
      );
    },
    // 图片预览
    preview(file) {
      let _this = this;
      if (!window.FileReader) return;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        _this.previewUrl = this.result;
      };
    },
    // 删除图片
    delFun() {
      this.previewUrl = "";
      this.$refs.upload.value = "";
      this.previewUrl = "";
    },
    cancelSendMsg() {
      this.$router.go(-1);
    },
  },
};
</script>
